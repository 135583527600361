import Vue from "vue";
import Vuex from "vuex";
import assistants from "./assistants.module";
import auth from "./auth.module";
import breadcrumbs from "./breadcrumbs.module";
import config from "./config.module";
import devices from "./devices.module";
import htmlClass from "./htmlclass.module";
import layoutBuilder from "./layout-builder.module";
import profile from "./profile.module";
import reactors from "./reactor.module";
import realtime from "./real-time.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    devices,
    assistants,
    layoutBuilder,
    realtime,
    reactors
  }
});
