import JwtService from "@/core/services/jwt.service";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = process.env.NODE_ENV !== "production" ? "" : "api";
    if (window.location.hostname === "localhost") {
      Vue.axios.defaults.baseURL = "api";
    } else {
      Vue.axios.defaults.baseURL = "";
    }
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    // Vue.axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Token ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    const addOnParam = {...params,'authenticity_token':JwtService.getToken(), 'utf8': '✓'};
    return Vue.axios.post(`${resource}`, addOnParam);
  },

  upload(resource, params) {
    params.append("authenticity_token",JwtService.getToken());
    params.append("utf8",'✓');
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    const addOnParam = {...params,'authenticity_token':JwtService.getToken(), 'utf8': '✓'};
    return Vue.axios.put(`${resource}/${slug}`, addOnParam);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    const addOnParam = {...params,'authenticity_token':JwtService.getToken(), 'utf8': '✓'};
    return Vue.axios.put(`${resource}`, addOnParam);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, params) {
    // const addOnParam = {...params,'authenticity_token':JwtService.getToken(), 'utf8': '✓'};
    const addOnParam = {...params,'authenticity_token':JwtService.getToken(), 'utf8': '✓',_method:'DELETE'};
    return Vue.axios({
      url: `${resource}`,
      method: 'delete',
      data: addOnParam,
  })
  }
};

export default ApiService;
