import ApiService from "@/core/services/api.service";

// action types
export const CREATE_LAYOUT = "createLayout";
export const DELETE_LAYOUT = "deleteLayout";
export const UPDATE_LAYOUT = "updateLayout";
export const FETCH_LAYOUTS = "fetchLayouts";
// mutation types
export const SET_LAYOUTS = "setLayouts";
export const SET_ERROR = "setError";


  const state= {
    layouts: [],
  };
  const getters= {
    getLayouts(){
      return state.layouts;
    }
  };
  const actions= {
    [FETCH_LAYOUTS](context) {
      ApiService.get("/layouts","v2.json")
        .then(({ data }) => {
          context.commit(SET_LAYOUTS, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    },
    [CREATE_LAYOUT](context, layout) {
      return new Promise(resolve => {
      ApiService.post("/layouts/v2.json", layout)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
      });
    },
    [DELETE_LAYOUT](context, layout) {
      return new Promise(resolve => {
      ApiService.delete("/layouts/v2/"+layout.id+".json")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
      });
    },
    [UPDATE_LAYOUT](context, layout) {
      return new Promise(resolve => {
      ApiService.put("/layouts/v2/"+layout.id+".json",{layout:layout.layout})
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
      });
    },
  };
  const mutations = {
    [SET_LAYOUTS](state, payload) {
      state.layouts = payload;
    },
    [SET_ERROR](state, errors) {
      console.log("there are errors on device state", errors);
    }
  }

  export default {
    state,
    actions,
    mutations,
    getters
  };