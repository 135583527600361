import ApiService from "@/core/services/api.service";

// action types
export const CREATE_REACTOR = "createReactor";
export const DELETE_REACTOR = "deleteReactor";
export const UPDATE_REACTOR = "updateReactor";
export const FETCH_REACTORS = "fetchReactors";
export const FORCE_MUTATE_REACTORS = "forceMutateReactors";
// mutation types
export const SET_REACTORS = "setReactors";
export const SET_ERROR = "setError";


  const state= {
    reactors: [],
  };
  const getters= {
    getReactors(){
      return state.reactos;
    }
  };
  const actions= {
    [FETCH_REACTORS](context) {
      return new Promise(resolve => {
      ApiService.get("/chain","v2.json")
        .then(({ data }) => {
          context.commit(SET_REACTORS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
      });
    },
    [CREATE_REACTOR](context, layout) {
      return new Promise(resolve => {
      ApiService.post("/chain/v2.json", layout)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
      });
    },
    [DELETE_REACTOR](context, layout) {
      return new Promise(resolve => {
      ApiService.delete("/chain/v2/"+layout.id+".json")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
      });
    },
    [UPDATE_REACTOR](context, layout) {
      return new Promise(resolve => {
      ApiService.put("/chain/v2/"+layout.id+".json",{layout:layout.layout, devicesIds: layout.devicesIds})
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
      });
    },
    [FORCE_MUTATE_REACTORS](context) {
      context.commit(SET_REACTORS, state.reactors);
    },
  };
  const mutations = {
    [SET_REACTORS](state, payload) {
      state.reactors = payload;
    },
    [SET_ERROR](state, errors) {
      console.log("there are errors on device state", errors);
    }
  }

  export default {
    state,
    actions,
    mutations,
    getters
  };