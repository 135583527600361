// Spain
export const locale = {
  LOGIN: {
    WELCOME: "Bienvenido",
    TO: "a",
    CREATE: "Crear",
    AN: "una",
    ACCOUNT: "cuenta",
    NEW: "¿Nuevo", 
    HERE: "Aqui?",
    EMAIL: "Correo Eléctronico",
    PASSWORD: "Contraseña",
    FORGOTPASSWORD: "Olvidó su Contraseña?",
    SIGNIN: "Acceder",
  }
};
