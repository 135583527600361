import ApiService from "@/core/services/api.service";

// action types

export const SET_ERROR = "setError";
export const RESET_ASSISTANTS = "purgeDevices";


export const FETCH_ASSISTANTS = "fetchAsistants";
export const STORE_ASSISTANTS = "storeAssistants";


const state = {
  assistants: [],
};

const getters = {
  getAssistants(state) {
    return state.assistants;
  }
};

const actions = {
  [FETCH_ASSISTANTS](context) {
    return new Promise(resolve => {
      ApiService.get("/assistant/v2","activity.json")
        .then(({ data }) => {
          // console.log("Here what post returns", data);
          context.commit(STORE_ASSISTANTS,data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
};

const mutations = {
  [RESET_ASSISTANTS](state) {
    state.assistants = [];
  },
  [STORE_ASSISTANTS](state, list) {
    state.assistants = list;
  },
  [SET_ERROR](state, errors) {
    console.log("there are errors on device state", errors);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
