// action types
export const REALTIME_INIT = "REALTIME_INIT";
export const REALTIME_PROPAGATE = "REALTIME_PROPAGATE";

export const SET_ERROR = "setError";
export const SET_MESSAGE ="setMessage";

  const state= {
    messages: [],
    last_message:null,
  };
  const getters= {
    getMessages(){
      return state.messages;
    },
    getLastMessage(){
        return state.last_message;
    }
  };
  const actions= {
    [REALTIME_INIT](context) {
        // no code at the moment
        context;
    },
    [REALTIME_PROPAGATE](context,message) {
        context.commit(SET_MESSAGE, message);
    },
}
  const mutations = {
    [SET_MESSAGE](state, payload) {
      state.last_message = JSON.parse(payload.message);
    },
    [SET_ERROR](state, errors) {
      console.log("there are errors on device state", errors);
    }
  }

  export default {
    state,
    actions,
    mutations,
    getters
  };