import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import jwtService from "../jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const UPDATE_PERSONAL_INFO = "updatePersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateAccountInfo";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

export const FETCH_USER_DETAILS = "fetchUserDetails";
export const FETCH_SECURITY_WORD = "fetchSecurityWord";
export const CREATE_ACCOUNT = "createAccount";

export const FETCH_ASSISTANT_CODES = "fetchAssistantCodes";
export const UPDATE_ASSISTANT_CODE = "updateAssistantCode";

const state = {
  errors: null,
  user: JSON.parse(localStorage.getItem("user")) || {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      // before login, we should GET the CSRF token and after that we can login
      ApiService.get("auth/v2/verify.json")
        .then(({ data }) => {
          jwtService.saveToken(data.token);
          ApiService.post("auth/v2/login.json", credentials)
            .then(({ data }) => {
              // save personal data to persist
              localStorage.setItem("user", JSON.stringify(data));
              context.commit(SET_AUTH, data);
              resolve(data);
            })
            .catch(({ response }) => {
              context.commit(SET_ERROR, response.data.errors);
            });
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    console.log("VERIFY_AUTH");
    if (JwtService.getToken()) {
      console.log("VERIFY_AUTH_2");
      ApiService.setHeader();
      ApiService.get("auth/v2/verify.json")
        .then(({ data }) => {
          //context.commit(SET_AUTH, data);
          data;
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_ACCOUNT_INFO](context) {
    return ApiService.put("").then(({ data }) => {
      context.commit(data);
      return data;
    });
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;
    return ApiService.put("/auth/v2/user/pwd", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [FETCH_USER_DETAILS](context) {
    // save the result in the state!!
    return new Promise((resolve) => {
      ApiService.get("/auth/v2/user.json")
        .then(({ data }) => {
          localStorage.setItem("user", JSON.stringify(data));
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [FETCH_SECURITY_WORD](context) {
    return new Promise((resolve) => {
      ApiService.get("/auth/signup/backwards.json")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [CREATE_ACCOUNT](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("/auth/signup.json", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_PERSONAL_INFO](context, userData) {
    return ApiService.put("/auth/v2/user.json", userData).then(({ data }) => {
      context.commit(data);
      return data;
    });
  },
  [FETCH_ASSISTANT_CODES](context) {
    return new Promise((resolve) => {
      ApiService.get("/assistant/v2/link.json")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_ASSISTANT_CODE](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("/assistant/v2/link.json", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    localStorage.removeItem("user");
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
